<template>
  <!-- pedido entregado -->
  <div class="vx-col w-1/3 pt-5">
    <vx-card card-background="#dce2f0" class="h-auto">
      <h4 @click="emitCollapse()" class="text-info mb-5" ref="delivered">Pedidos entregues</h4>

      <i v-if="orders.length === 0">
        <feather-icon icon="AlertCircleIcon" class="align-middle mr-2"/>
        Não há pedidos para entrega!
      </i>
      <!-- iteracion de pedidos entregados -->
      <div v-for="order in orders" v-bind:key="order.id">
        <vx-card class="mt-2">
          <!-- id del pedido -->
          <div class="w-full text-right">
            <span class="font-thin text-info">Pedido No. {{ order.order.order_id }}</span>
          </div>
          <!-- datos del cliente -->
          <div class="leading-none">
            <p class="">Cliente</p>
            <p class="font-bold text-dark">{{ `${order.user.name} ${order.user.lastname}` }}</p>
            <div> <b>ID:</b> {{ order.user.identification }}</div>
            <div> {{ `${ order.user.address }, ${ order.user.city }` }} </div>
            <div> {{ order.user.email }}</div>
            <div> {{ order.user.phone }}</div>
          </div>
          <!-- tiempo de entrega -->
          <!-- <div class="mt-5">
            <h5 class="text-info"> Tiempo de Entrega </h5>
            {{ order.delivery_time }}
          </div> -->
          <!-- boton pedido entregado -->
          <!-- <vs-button color="success" class="rounded-full mt-5 w-full" @click="deliverOrder( order.id )">
            Pedido Entregado
          </vs-button> -->

          <vs-divider />
          <!-- productos y detalles -->
          <vs-collapse>
          <vs-collapse-item icon-pack="feather" icon-arrow="icon-chevrons-down">
            <div slot="header"></div>
            <!-- productos -->
            <div v-for="product in order.order.order_items" v-bind:key="product.id" class="vx-row mt-2">
              <!-- nombre del producto -->
              <div class="w-3/5 sm:w-3/5">
                <div class="tooltip w-full">
                  <p class="text-sm truncate"> {{ product.title }} </p>
                  <p class="text-sm"> <b>SKU:</b> {{ product.sku | sku }} </p>
                  <span class="tooltiptext tooltip-bottom">
                    <img v-if="product.image" :src="product.image" width="100px"/>
                    <img v-else src="https://firebasestorage.googleapis.com/v0/b/sitidoctor-marketplace-dev.appspot.com/o/images%2Fdefault-product.png?alt=media&token=2179ec02-d62c-4cc0-9a4f-4da5142b678f" width="100px" />
                    <span> {{ product.title }} </span>
                  </span>
                </div>
              </div>
              <!-- cantidad de producto -->
              <div class="w-12 text-center">
                <p class="text-sm"> x{{ product.quantity }} </p>
              </div>
              <!-- precio -->
              <div class="w-1/3 sm:w-1/5 text-right">
                <span
                  class="text-sm text-danger"
                  :class="{
                    'text-black': product.discount === 0,
                    'line-through': product.discount !== 0
                  }"
                >
                  {{ product.price | coin }}
                </span>
                <span class="text-sm" v-if=" product.discount !== 0 "> {{ product.discount | coin }} </span>
              </div>
            </div>

            <vs-divider class="mt-5 mb-5" />
            <!-- detalles -->
            <h5 class="text-info"> Detalhes do pedido: </h5>
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <span>Forma de pagamento</span>
              </div>

              <div class="vx-col w-1/2 text-right">
                <span class="text-dark text-xl">{{ order.order.payment_method }}</span>
              </div>

              <div class="vx-col w-1/2 mt-5">
                <span>Produtos</span>
              </div>

              <div class="vx-col w-1/2 text-right mt-5">
                <span>{{ ( order.order.subTotal - order.order.discount ) | coin }}</span>
              </div>

              <div class="vx-col w-1/2">
                <span>Cupom de desconto</span>
              </div>

              <div class="vx-col w-1/2 text-right">
                <span class="text-danger line-through"> - {{ order.order.promo | coin }} </span>
              </div>

              <div class="vx-col w-1/2">
                <span> Custo de envio </span>
              </div>

              <div class="vx-col w-1/2 text-right">
                <span>{{ order.order.delivery_price | coin }}</span>
              </div>

              <div class="vx-col w-1/2">
                <p class="text-dark text-xl">Total</p>
              </div>

              <div class="vx-col w-1/2 text-right">
                <p class="text-dark text-xl">{{ order.order.grandTotal | coin }}</p>
              </div>
            </div>
          </vs-collapse-item>
          </vs-collapse>
        </vx-card>
      </div>
    </vx-card>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    orders: { required: true },
    storeId: { type: Number, required: true },
  },

  data : () => ({
    ordersCopy    : [],
    orderSelected : { id: 0 },
  }),

  methods: {
    async deliverOrder( orderId ) {
      if( !orderId ) return;
      // copy to find order and set emit
      this.ordersCopy = JSON.parse( JSON.stringify( this.orders ) );
      // set order to selecetd
      this.orderSelected.id = orderId;
      this.$vs.loading();
      await this.$store.dispatch( 'tracking/deliverOrder', orderId );
      this.$vs.loading.close();
      this.emulateDeliveredOrdersClick();
    },

    emulateDeliveredOrdersClick() {
      const el = this.$refs.delivered;
      if( el ) el.click();
    },

    emitCollapse( ) {
      const order = this.ordersCopy.find( order => order.id === this.orderSelected.id );
      if( !order ) return; // no order finded break the process

      this.$emit( 'setCollapseTrue', order.shop.shop_id ); // set event           
      this.orderSelected = { id: 0, }; // set order selected id to zero
      this.ordersCopy = []; // Clean orders copy
    },
  },
});
</script>
