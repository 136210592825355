<template>
    <div>
        <div class="vx-row">
            <div v-if="loading" class="vx-col w-full mb-2">
                <vx-card card-background="#e9ecf3">
                    <div class="spinner">
                        <div class="double-bounce1"></div>
                        <div class="double-bounce2"></div>
                    </div>
                </vx-card>
            </div>

            <div v-if="!loading && ordersGrouped.length === 0" class="vx-col w-full mb-2">
                <vx-card card-background="#e9ecf3">
                    <div class="vx-row">
                        
                        <div class="vx-col w-full">
                            <i>
                                <feather-icon icon="AlertCircleIcon" class="align-middle mr-2"/>
                                Não há lojas com pedidos em andamento!
                            </i>
                        </div>
                        
                    </div>
                </vx-card>
            </div>

            <div v-for="orders in ordersGrouped" v-bind:key="orders.id" class="vx-col w-full mb-2">
                <vx-card card-background="#e9ecf3">
                    <div class="vx-row">
                        
                        <!-- nombre de la tienda -->
                        <div class="vx-col w-2/2">
                            <h4 class="text-info"> {{ getStoreName( orders["1"][0]["shop"]["shop_id"] ) }} </h4>
                        </div>  

                        <!-- estado -->
                        <!-- <div class="vx-col w-1/2">
                            <div class="text-info float-right">
                                <h3 class="inline-block align-middle mr-2 text-info cursor-pointer" @click="changeStoreStatus( orders[0] )"> Estado </h3>
                                <vs-switch color="success" :value="getStoreStatus( orders[0] )" @input="changeStoreStatus( orders[0] )" class="inline-block align-middle"/>
                            </div>
                        </div> -->

                        <!-- info pedidos -->
                        <div class="vx-col w-full">
                            <div class="bg-white mt-2 rounded-lg p-4">
                                <div class="vx-row">
                                    <div class="vx-col lg:w-1/5 sm:w-full">
                                        <h3 class="text-info mt-3"> {{ orders[1][0].shop.name }} </h3>
                                    </div>

                                    <!-- chips pedidos + boton collapsed -->
                                    <div class="vx-col lg:w-3/5 sm:w-full">
                                        <div class="flex content-center sm:justify-center">
                                            <!-- chip nuevo pedido -->
                                            <div @click="toggleCollapse( orders[0], 'new' )" class="flex-1 bg-danger lg:w-auto rounded-full pl-5 pt-2 pr-10 text-white h-10 mr-5">
                                                <div class="chip-number-danger rounded-full w-6 h-6 inline-block text-center"> {{ getNewOrders( orders[1] ).length }} </div>
                                                <span class="pl-1">
                                                    Novas ordens
                                                </span>
                                            </div>

                                            <!-- chip pedido en curso -->
                                            <div @click="toggleCollapse( orders[0], 'progress' )" class="flex-1 bg-warning lg:w-auto rounded-full pl-5 pt-2 pr-10 text-white lg:inline-block mr-5">
                                                <div class="chip-number-warning rounded-full w-6 h-6 inline-block text-center"> {{ getPreparingOrders( orders[1] ).length }} </div>
                                                <span class="pl-1">
                                                    <span class="sm:hidden lg:inline-block">Pedidos em andamento</span>
                                                </span>
                                            </div>

                                            <!-- chip pedido en entrega -->
                                            <!-- <div @click="toggleCollapse( orders[0], 'delivery' )" class="flex-1 bg-success lg:w-auto rounded-full pl-5 pr-3 pt-2 text-white lg:inline-block mr-5">
                                                <div class="chip-number-success rounded-full w-6 h-6 inline-block text-center"> {{ getDeliveredOrders( orders[1] ).length }} </div>
                                                <span class="pl-1">
                                                    <span class="sm:hidden lg:inline-block">Pedidos entregues</span>
                                                </span>
                                            </div> -->

                                            <!-- chip desplegar -->
                                            <!-- <div @click="toggleCollapse( orders[0] )" class="flex-none cursor-pointer arrow-icons sm:invisible lg:visible">
                                                <img v-if="orders.collapsed" :src="require(`@/assets/icons/ArrowUpIcon.svg`)" width="35px"/>
                                                <img v-else :src="require(`@/assets/icons/ArrowDownIcon.svg`)" width="35px"/>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- nuevo pedido -->
                        <new-orders-panel
                            v-show="true"
                            :orders=" getNewOrders( orders[1] ) "
                            :storeId=" orders[0] "
                            @setCollapseTrue="setCollapseTrue( $event, 'progress' )"
                            class="panel-show sm:w-full lg:w-1/2"
                        />

                        <!-- preparando pedido -->
                        <orders-preparing-panel
                            v-show="true"
                            :orders=" getPreparingOrders( orders[1] ) "
                            :storeId=" orders[0] "
                             @setCollapseTrue="setCollapseTrue( $event, 'delivery' )"
                             class="panel-show sm:w-full lg:w-1/2"
                            />

                        <!-- pedidos en entrega -->
                        <!-- <orders-delivered-panel
                            v-show="true"
                            :orders=" getDeliveredOrders( orders[1] ) "
                            :storeId=" orders[0] "
                            @setCollapseTrue="setCollapseTrue( $event, 'new' )"
                            class="panel-show sm:w-full lg:w-1/3"
                        /> -->
                        
                    </div>
                </vx-card>
            </div>
            
        </div>

    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import NewOrdersPanel from './NewOrderPanel.vue';
import OrdersPreparingPanel from './OrdersPreparingPanel.vue';
import OrdersDeliveredPanel from './OrdersDeliveredPanel.vue';

export default Vue.extend({

    data() {
        return {
            showPanel: {
                all       : false,
                new       : false,
                progress  : false,
                delivered : false,
            },
            loading : false,
        }
    },

    created() {
        // update page title
        this.$store.dispatch( 'updatePageTitle', {
            title: 'Minhas ordens',
            subtitle: 'Lista de pedidos em andamento',
        });

        this.getAllStores();
    },

    methods: {
        async getAllStores() {
            this.loading = true;
            const res = await this.$store.dispatch( 'stores/getAllStores' );
            this.loading = false;
            if( res ) this.filterOrders();
        },

        filterOrders() {
            // map the store id's
            const storesIds = this.stores.map( store => store.id );
            this.$vs.loading();
            this.$store.dispatch( 'tracking/filterOrders', { storesIds } )
                .then( () => {
                    this.$vs.loading.close();
                });
        },

        getStoreStatus( storeId ) {
            const store = this.stores.find( store => store.id === storeId );

            if( store ) {
                if( store.status === 1 ) return true;
                return false;
            }
            
            return false;
        },

        getStoreName( storeId ) {
            const store = this.stores.find( store => store.id === storeId );

            if( store ) {                
                if( store.name ) return store.name;
                return '';
            }
            
            return 'false';
        },

        getNewOrders( orders=[] ) {
            const newOrders = orders.filter( order => order.status === parseInt( process.env.VUE_APP_NEW_ORDER ) );
            return newOrders;
        },

        getPreparingOrders( orders=[] ) {
            const preparingOrders = orders.filter( order => order.status === parseInt( process.env.VUE_APP_TRACKING_PREPARING_ORDER ) );
            return preparingOrders;
        },

        getDeliveredOrders( orders=[] ) {
            const deliveredOrders = orders.filter( order => order.status === parseInt( process.env.VUE_APP_TRACKING_ORDER_DISPATCHED ) );
            return deliveredOrders;
        },
        
        changeStoreStatus( storeId ) {            
            this.$vs.loading();
            this.$store.dispatch( 'tracking/toggleStoreStatus', storeId )
                .then( () => this.$vs.loading.close() );
        },

        /* eslint-disable */
        toggleCollapse( storeId, orderType="all" ) {
            // This array copy is used to avoid conflicts vuex mutations
            const ordersCopy = JSON.parse( JSON.stringify(this.ordersGrouped) );
            const orders     = ordersCopy.find( orders => orders[0] === storeId);
            const docWidth   = document.body.clientWidth; // 768 iPad
            
            orders.collapsed = !orders.collapsed;

            if( docWidth < 769 ) {                
                if( !orders.collapsed && orderType === "new" ) orders.panel = 'new';
                if( !orders.collapsed && orderType === "progress" ) orders.panel = 'progress';
                if( !orders.collapsed && orderType === "delivery" ) orders.panel = 'delivery';
                else if( orders.collapsed ) orders.panel = '';
                
            } else {
                if( !orders.collapsed ) orders.panel = 'all';
                else orders.panel = '';
            }           

            this.ordersGrouped = ordersCopy;
            
        },

        setCollapseTrue( storeId, orderType="all" ) {
            setTimeout( () => {
                const orderGroup = this.ordersGrouped.find( order => order[0] === storeId );
                const docWidth = document.body.clientWidth; // 768 iPad

                if( !orderGroup ) return; // IF order group has not orders in process
                if( docWidth >= 768 ) this.toggleCollapse( storeId, orderType )
            }, 500 );
            
        }
    },

    computed: {
        stores() {
            return this.$store.state.stores.stores;
        },

        newOrders() {
            return this.$store.state.tracking.newOrders;
        },

        preparingOrders() {
            const store  = this.$store;
            const orders = store.state.tracking.preparingOrders;
            const ordersMapped = orders.map( order => {
                let ob = { ...order };
                // ob.delivery_time = { value: null, time: ''}                    
                return ob;
            });
            
            return ordersMapped;
        },

        deliveredOrders() {
            return this.$store.state.tracking.deliveredOrders;
        },

        ordersGrouped: {
            get() {
                return this.$store.state.tracking.filteredStores;
            },
            set( val ) {
                this.$store.commit( 'tracking/SET_FILTERED_ORDERS_MAPPED', val );
            }
        },
    },

    components: {
        NewOrdersPanel,
        OrdersPreparingPanel,
        OrdersDeliveredPanel,
    }

})
</script>

<style lang="scss" scoped>
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.vx-card {
    box-shadow: none;
}
.chip-number-danger {
    background: #fb98ac;
    opacity: .9;
}
.chip-number-warning {
    background: #fbd8ac;
    opacity: .9;
}
.chip-number-success {
    background: #a2eacb;
    opacity: .9;
}
.arrow-icons {
    &:hover {
        transform: rotate(180deg);
    }
    transition: transform .3s;
}
.panel-show {
    animation: fadeIn 1s;
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
</style>
