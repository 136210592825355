<template>
  <div class="vx-col w-1/3 pt-5">
    <vx-card card-background="#dce2f0" class="h-auto">
      <h4 @click="emitCollapse()" class="text-info mb-5" ref="newOrder"> Novas ordens </h4>

      <i v-show="orders.length === 0">
        <feather-icon icon="AlertCircleIcon" class="align-middle mr-2"/>
        Não há novos pedidos!
      </i>

      <!-- iteracion de pedidos nuevos -->
      <div v-for="order in orders" v-bind:key="order.id">
        <vx-card class="mt-2 card_bo">
          <!-- id del pedido -->
          <div class="w-full text-right">
            <span class="font-thin text-info">Pedido No: {{ order.order.order_id }}</span>
          </div>
          <div class="w-full text-right">
            <span class="font-thin text-info">Estado: {{ order.status_msg }}</span>
          </div>

          <!-- datos del cliente -->
          <div class="leading-none">
            <p class="text-info">Cliente</p>
            <p class="font-bold text-dark">{{ `${order.user.name} ${order.user.lastname}` }}</p>
            <div> <b>ID</b> {{ order.user.identification }}</div>
            <div> {{ `${ order.user.address }, ${ order.user.city }` }} </div>
            <div> {{ order.user.email }}</div>
            <div> {{ order.user.phone }}</div>
          </div>

          <vs-divider />
          <vs-collapse>
            <vs-collapse-item icon-pack="feather" icon-arrow="icon-chevrons-down">
              <div slot="header"></div>
              
              <!-- productos -->
              <div v-for="product in order.order.order_items" v-bind:key="product.id" class="vx-row mt-2">
                <!-- nombre del producto -->
                <div class="w-1/2 sm:w-3/5">
                  <div class="tooltip w-full">
                    <p class="text-sm truncate"> {{ product.title }} </p>
                    <p class="text-sm"> <b>SKU:</b> {{ product.sku | sku }} </p>
                    <span class="tooltiptext tooltip-bottom">
                      <img v-if="product.image" :src="product.image" width="100px"/>
                      <img v-else src="https://firebasestorage.googleapis.com/v0/b/sitidoctor-marketplace-dev.appspot.com/o/images%2Fdefault-product.png?alt=media&token=2179ec02-d62c-4cc0-9a4f-4da5142b678f" width="100px" />
                      <span> {{ product.title }} </span>
                    </span>
                  </div>
                </div>
                <!-- cantidad de producto -->
                <div class="w-12 text-center">
                  <p class="text-sm"> x{{ product.quantity }} </p>
                </div>
                <!-- precio -->
                <div class="w-1/3 sm:w-1/5 text-right">
                  <span
                    class="text-sm text-danger"
                    :class="{
                      'text-dark ': product.discount === 0,
                      'line-through': product.discount !== 0
                    }"
                  >
                    {{ product.price | coin }}
                  </span>
                  <span class="text-sm text-dark " v-if=" product.discount !== 0 "> {{ product.discount | coin }} </span>
                </div>
              </div>

              <vs-divider class="mt-5 mb-5" />

              <!-- detalles -->
              <h5 class="text-info"> Detalhes do pedido: </h5>
              <div class="vx-row">
                <div class="vx-col w-1/2">
                  <span class="truncate">Forma de pagamento</span>
                </div>

                <div class="vx-col w-1/2 text-right">
                  <span class="text-dark text-xl truncate">{{ order.order.payment_method }}</span>
                </div>

                <div class="vx-col w-1/2 mt-5">
                  <span>Produtos</span>
                </div>

                <div class="vx-col w-1/2 text-right mt-5">
                  <span>{{ ( order.order.subTotal - order.order.discount ) | coin }}</span>
                </div>

                <div class="vx-col w-1/2">
                  <span class="truncate">Cupom de desconto</span>
                </div>

                <div class="vx-col w-1/2 text-right">
                  <span class="text-danger line-through"> - {{ order.order.promo | coin }} </span>
                </div>

                <div class="vx-col w-1/2">
                  <span> Custo de envio </span>
                </div>

                <div class="vx-col w-1/2 text-right">
                  <span>{{ order.order.delivery_price | coin }}</span>
                </div>

                <div class="vx-col w-1/2">
                  <p class="text-dark text-xl">Total</p>
                </div>

                <div class="vx-col w-1/2 text-right">
                  <p class="text-dark text-xl">{{ order.order.grandTotal | coin }}</p>
                </div>
              </div>  

              <!-- aceptar - rechazar -->
              <!-- <vs-button type="border" color="danger" class="rounded-full mt-5 float-right" @click="setRejectDialog( order.id )">
                Rechazar
              </vs-button>

              <vs-button class="rounded-full mt-5 float-right mr-2" @click="setAcceptDialog( order.id )">
                Aceptar
              </vs-button> -->
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>
      </div>
    </vx-card>

    <!-- modal aceptar orden -->
    <vs-prompt :active.sync="acceptOrderDialog" buttons-hidden>
      <div class="w-full">
        <vs-button  class="float-right" @click="closeAcceptDialog()" color="transparent" text-color="#666">
          <feather-icon icon='XIcon'  />
        </vs-button>
      </div>
            
      <div class="w-full flex justify-center mb-5">
        <img :src="require(`@/assets/icons/CheckGreenIcon.svg`)" alt="Delete Icon" width="128px" />
      </div>
            
      <div class="text-center text-gray-400">
        <h3 class="mb-2">Aceptar Orden</h3>
        <p>¿Estás seguro que deseas aceptar ésta orden?</p>

        <p><vs-button class="mt-5 rounded-full" @click="acceptOrder()">Aceptar</vs-button></p>
        <p><vs-button  class="mt-2 text-primary" @click="closeAcceptDialog()" color="#FFF">Cancelar</vs-button></p>
      </div>
    </vs-prompt>

    <!-- modal rechazar orden -->
    <vs-prompt :active.sync="rejectOrderDialog" buttons-hidden>
      <div class="w-full">
        <vs-button  class="float-right" @click="closeRejectDialog()" color="transparent" text-color="#666">
          <feather-icon icon='XIcon'  />
        </vs-button>
      </div>
            
      <div class="w-full flex justify-center mb-5">
        <img :src="require('@/assets/icons/CloseIcon.svg')" alt="close icon" width="101px" />
      </div>
            
      <div class="text-gray-400">
        <h3 class="mb-2 text-center">Rechazar Orden</h3>
        <p>¿Porqué deseas rechazar esta orden?</p>

        <v-select
          :options="rejectMessages"
          v-model="reject.id"
          label="name"
          :reduce="rejectMessage => rejectMessage.id"
          class="mt-2 mb-2"
        >
          <template slot="option" slot-scope="option">
            {{ option.name }}
          </template>

          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>

        <vs-input
          v-if="reject.id === 5"
          label="motivo"
          v-model="reject.message" 
          :danger="rejectMessageError"
          val-icon-danger="clear"
          class="w-full"
          id="rejectMessage"
        />
                
        <vs-button type="border" color="#666" class="mt-5 rounded-full" @click="closeRejectDialog()">Cancelar</vs-button>
        <vs-button type="filled" color="danger" class="mt-5 mb-5 rounded-full float-right" @click="rejectOrder()">Rechazar Pedido</vs-button>
      </div>
    </vs-prompt>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import vSelect from 'vue-select';

export default Vue.extend({
  props: {
    orders: { required: true },
    storeId: { type: Number, required: true },
  },

  data : () => ({
    acceptOrderDialog  : false,
    rejectOrderDialog  : false,
    orderSelected      : { id: 0 },
    reject             : { id: 1, message: '' },
    rejectMessageError : false,
    ordersCopy         : [],
  }),

  watch: {
    'reject.message'( val ) {
      if( this.rejectOrderDialog ) this.validateRejectMessage( val );
    },
  },
    
  methods: {
    setRejectDialog( orderId ) {
      this.$store.dispatch( 'tracking/getRejectMessages' );
      this.orderSelected     = { id: orderId, };
      this.rejectOrderDialog = true;
    },

    setAcceptDialog( orderId ) {
      this.orderSelected     = { id: orderId, };
      this.acceptOrderDialog = true;
    },

    closeAcceptDialog() {
      this.orderSelected     = { id: 0, };
      this.acceptOrderDialog = false;
    },

    async acceptOrder() {
      

      if( this.orderSelected.id === 0 ) {
          alert( 'Ingrese un key de orden válido.' );
          return;
      }

      // copy to find order and set emit
      this.ordersCopy = JSON.parse( JSON.stringify( this.orders ) );
      this.$vs.loading();
      const res = await this.$store.dispatch( 'tracking/acceptOrder', this.orderSelected.id );
      this.$vs.loading.close();
      
      if( res ) {
          this.emulateNewOrdersClick();
          this.acceptOrderDialog = false;
      }    
    },

    emitCollapse( ) {
      const order = this.ordersCopy.find( order => order.id === this.orderSelected.id );
      if( !order ) return; // no order finded break the process

      this.$emit( 'setCollapseTrue', order.shop.shop_id ); // set event           
      this.orderSelected = { id: 0, }; // set order selected id to zero
      this.ordersCopy = []; // Clean orders copy
    },

    emulateNewOrdersClick() {
      const el = this.$refs.newOrder;
      if( el ) el.click();
    },

    validateRejectMessage( msg ) {
      if( msg.length === 0 ) {
        this.rejectMessageError = true;

        this.$vs.notify({
            title: 'Ingrese un motivo',
            text: '',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
        });

        return false;
      }

      this.rejectMessageError = false;
      return true;
    },

    closeRejectDialog() {
      this.orderSelected     = { id: 0, };
      this.rejectOrderDialog = false;
      this.reject.id         = 0;
      this.reject.message    = '';
    },

    async rejectOrder() {
      

      if( this.orderSelected.id === 0 ) {
        alert( 'Ingrese un key de orden válido.' );
        return;
      }

      if( this.reject.id === 5 ) {
        const isValidRejectMsg = this.validateRejectMessage( this.reject.message );
        if( !isValidRejectMsg ) return;
      }

      const rejectOrderData = {
        orderSelectedId  : this.orderSelected.id,
        rejected_id      : this.reject.id,
        rejected_message : this.reject.message,
      };

      // copy to find order and set emit
      this.ordersCopy = JSON.parse( JSON.stringify( this.orders ) );
      this.$vs.loading();
      const res = await this.$store.dispatch( 'tracking/rejectOrder', rejectOrderData );
      this.$vs.loading.close();
              
      if( res ) {
        this.emulateNewOrdersClick();
        this.rejectOrderDialog = false;
      }
    },
  },

  computed: {
    rejectMessages() {
      return this.$store.state.tracking.rejectMessages;
    },
  },

  components: {
    vSelect,
  }
});
</script>
<style>
.vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 1.5rem;
}
</style>
