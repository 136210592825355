<template>
  <div class="vx-col w-1/3 pt-5">
    <vx-card card-background="#dce2f0" class="h-auto">
      <h4 @click="emitCollapse()" class="text-info mb-5" ref="preparing">Pedidos em andamento</h4>

      <i v-if="orders.length === 0">
        <feather-icon icon="AlertCircleIcon" class="align-middle mr-2"/>
        Não há pedidos em preparação!
      </i>

      <!-- iteracion de pedidos en preparación -->
      <div v-for="order in orders" v-bind:key="order.id">
        <vx-card class="mt-2">
          <!-- id del pedido -->
          <div class="w-full text-right">
            <span class="font-thin text-info">Pedido No: {{ order.order.order_id }}</span>
          </div>
          <div class="w-full text-right">
            <span class="font-thin text-info">Estado: {{ order.status_msg }}</span>
          </div>

          <!-- datos del cliente -->
          <div class="leading-none">
            <p class="text-info">Cliente</p>
            <p class="font-bold text-dark">{{ `${order.user.name} ${order.user.lastname}` }}</p>
            <div> <b>ID:</b> {{ order.user.identification }}</div>
            <div> {{ `${ order.user.address }, ${ order.user.city }` }} </div>
            <div> {{ order.user.email }}</div>
            <div> {{ order.user.phone }}</div>
          </div>

          <!-- tiempo de entrega -->
          <div class="mt-5">
            <!-- <h5 class="text-info"> Tiempo de Entrega </h5>
            <div class="flex">
              <vs-input
                type="number"
                :value="order.delivery_time.value"
                :danger="!order.delivery_time.time || order.delivery_time.value < 1"
                val-icon-danger="clear"
                @input="changeDeliveryTime( storeId, order.id, $event, order.delivery_time.time )"
                class="w-1/4 inline-block"
              />

              <v-select
                :options="deliveryTimes"
                :value="order.delivery_time.time"
                @input="changeDeliveryTime( storeId, order.id, order.delivery_time.value, $event )"
                label="name"
                :reduce="delTime => delTime.name"
                class="w-3/4 inline-block"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }}
                </template>

                <template slot="selected-option" slot-scope="option">
                  {{ option.name }}
                </template>
              </v-select>
            </div> -->

            <!-- mensaje de error -->
            <!-- <span v-if="!order.delivery_time.time || order.delivery_time.value < 1" class="text-danger">
              Ingrese un tiempo de entrega válido
            </span> -->
          </div>

          <vs-divider />
          <vs-collapse>
          <vs-collapse-item icon-pack="feather" icon-arrow="icon-chevrons-down">
            <div slot="header"></div>
              <!-- productos -->
              <div v-for="product in order.order.order_items" v-bind:key="product.id" class="flex mt-2 w-full justify-between">
                <!-- nombre del producto -->
                <div class="w-1/2 sm:w-3/5">
                  <div class="tooltip w-full">
                    <p class="text-sm truncate"> {{ product.title }} </p>
                    <p class="text-sm"> <b>SKU:</b> {{ product.sku | sku }} </p>
                    <span class="tooltiptext tooltip-bottom">
                      <img v-if="product.image" :src="product.image" width="100px"/>
                      <img v-else src="https://firebasestorage.googleapis.com/v0/b/sitidoctor-marketplace-dev.appspot.com/o/images%2Fdefault-product.png?alt=media&token=2179ec02-d62c-4cc0-9a4f-4da5142b678f" width="100px" />
                      <span> {{ product.title }} </span>
                    </span>
                  </div>
                </div>
                <!-- cantidad de producto -->
                <div class="w-12 text-center">
                  <p class="text-sm"> x{{ product.quantity }} </p>
                </div>
                <!-- precio -->
                <div class="w-1/3 sm:w-1/5 text-right">
                  <span
                    class="text-sm text-danger"
                    :class="{
                      'text-black': product.discount === 0,
                      'line-through': product.discount !== 0
                    }"
                  >
                    {{ product.price | coin }}
                  </span>
                  <span class="text-sm" v-if=" product.discount !== 0 "> {{ product.discount | coin }} </span>
                </div>
              </div>

              <vs-divider class="mt-5 mb-5" />

              <!-- detalles -->
              <h5 class="text-info"> Detalhes do pedido: </h5>
              <div class="vx-row">
                <div class="vx-col w-1/2">
                  <span>Forma de pagamento</span>
                </div>

                <div class="vx-col w-1/2 text-right">
                  <span class="text-dark text-xl">{{ order.order.payment_method }}</span>
                </div>

                <div class="vx-col w-1/2 mt-5">
                  <span>Produtos</span>
                </div>

                <div class="vx-col w-1/2 text-right mt-5">
                  <span>{{ ( order.order.subTotal - order.order.discount ) | coin }}</span>
                </div>

                <div class="vx-col w-1/2">
                  <span>Cupom de desconto</span>
                </div>

                <div class="vx-col w-1/2 text-right">
                  <span class="text-danger line-through"> - {{ order.order.promo | coin }} </span>
                </div>

                <div class="vx-col w-1/2">
                  <span> Custo de envio </span>
                </div>

                <div class="vx-col w-1/2 text-right">
                  <span>{{ order.order.delivery_price | coin }}</span>
                </div>

                <div class="vx-col w-1/2">
                  <p class="text-dark text-xl">Total</p>
              </div>

              <div class="vx-col w-1/2 text-right">
                <p class="text-dark text-xl">{{ order.order.grandTotal | coin }}</p>
              </div>
            </div>

            <!-- boton pedido despachado -->
            <!-- <vs-button class="rounded-full mt-5 w-full" @click="setDispatchOrderDialog( order.id )">
              Pedido Despachado
            </vs-button> -->
          </vs-collapse-item>
          </vs-collapse>
        </vx-card>
      </div>
    </vx-card>

    <!-- modal despachar orden -->
    <vs-prompt :active.sync="dispatchOrderDialog" buttons-hidden>
      <div class="w-full">
        <vs-button  class="float-right" @click="closeDispatchOrderDialog()" color="transparent" text-color="#666">
          <feather-icon icon='XIcon'  />
        </vs-button>
      </div>
            
      <div class="w-full flex justify-center mb-5">
        <img :src="require(`@/assets/icons/CheckGreenIcon.svg`)" alt="Delete Icon" width="128px" />
      </div>
            
      <div class="text-center text-gray-400">
        <h3 class="mb-2">Despachar Orden</h3>
        <p>¿Estás seguro que deseas despachar ésta orden?</p>

        <p><vs-button class="mt-5 rounded-full" @click="dispatchOrder()">Despachar</vs-button></p>
        <p><vs-button  class="mt-2 text-primary" @click="closeDispatchOrderDialog()" color="#FFF">Cancelar</vs-button></p>
      </div>
    </vs-prompt>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import vSelect from 'vue-select';

export default Vue.extend({
  props: {
    orders: { required: true },
    storeId: { type: Number, required: true },
  },

  data : () => ({
    dispatchOrderDialog : false,
    orderSelected       : { id: 0 },
    ordersCopy          : [],
    deliveryTimes       : [
        { id: 1, name: 'Minutes', },
        { id: 2, name: 'Hours', },
        { id: 3, name: 'Days', },
        { id: 4, name: 'Weeks', },
    ],
  }),

  methods: {
    changeDeliveryTime( storeId, orderId, value, time ) {
      // Copy of orders without reference
      const allOrders = JSON.parse( JSON.stringify( this.ordersGrouped) );
      // get orders obj by store id
      const ordersSelected = allOrders.find( orders => orders[0] === storeId );
      // Get the orders only
      const orders = ordersSelected[1];
      // get the order by order id
      const order = orders.find( order => order.id === orderId );
      // Set new time and new value
      order.delivery_time = { value, time }
      // set the new vals and commit
      this.ordersGrouped = allOrders;
    },

    setDispatchOrderDialog( orderId ) {
      const order = this.orders.find( order => order.id === orderId );

      if( order.delivery_time.value < 1 || !order.delivery_time.time ) {
        this.$vs.notify({
            title: 'Ingrese un tiempo de entrega válido',
            text: '',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
        });

        return;
      }            

      this.orderSelected       = order;
      this.dispatchOrderDialog = true;
    },

    async dispatchOrder() {
      if( this.orderSelected.id === 0 ) {
        alert( 'Ingrese un key de orden válido.' );
        return;
      }
      // copy to find order and set emit
      this.ordersCopy = JSON.parse( JSON.stringify( this.orders ) );
      const order = this.ordersCopy.find( order => order.id === this.orderSelected.id );
      // translate time 
      order.delivery_time.time = this.orderSelected.delivery_time.time ;
        
      this.$vs.loading();
      const res = await this.$store.dispatch( 'tracking/dispatchOrder', order );
      this.$vs.loading.close();
                
      if( res ) {
        this.emulatePreparingOrdersClick();
        this.dispatchOrderDialog = false;
      }
    },

    emulatePreparingOrdersClick() {
      const el = this.$refs.preparing;
      if( el ) el.click();
    },

    emitCollapse( ) {
      const order = this.ordersCopy.find( order => order.id === this.orderSelected.id );
      if( !order ) return; // no order finded break the process

      this.$emit( 'setCollapseTrue', order.shop.shop_id ); // set event           
      this.orderSelected = { id: 0, }; // set order selected id to zero
      this.ordersCopy = []; // Clean orders copy
    },

    closeDispatchOrderDialog() {
      this.orderSelected       = { id: 0 };
      this.dispatchOrderDialog = false;
    },
  },

  computed: {
    ordersGrouped: {
      get() { return this.$store.state.tracking.filteredStores; },
      set( val ) { this.$store.commit( 'tracking/SET_FILTERED_ORDERS_MAPPED', val ); }
    },
  },

  components: {
    vSelect,
  }
});
</script>
